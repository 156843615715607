import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { GatsbyImageProps, GatsbyImage } from 'gatsby-plugin-image';
import { grey3, grey5 } from '@lib/components/bedrock/SwColors';
import { SwPaper, SwPaperProps } from '@lib/components/bedrock/SwPaper';
import SwLink from '@lib/components/bedrock/typography/SwLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { getDomaineName } from '@lib/helpers/string';

export type CompanyMetaProps = SwPaperProps<'div'> & {
    description: string;
    industry: string;
    companySize: string;
    headquarter: string;
    website: string;
    logo: {
        alt: string;
        gatsbyImageData: GatsbyImageProps['image'];
    };
};

const DescriptionList = styled('dl')`
    & > dd:not(:last-child) {
        padding-block-end: 8px;
    }
`;

const LogoContainer = styled('div')`
    border: 1px solid ${grey5};
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    width: 128px;
    height: 64px;
    margin-block-end: 16px;
    display: grid;
    place-items: center;
`;

export const CompanyMeta: React.FC<CompanyMetaProps> = (props) => {
    const { companySize, headquarter, industry, logo, description, website, ...rest } = props;
    const { t } = useTranslation();

    return (
        <SwPaper {...rest}>
            <LogoContainer>
                <GatsbyImage
                    alt={logo.alt}
                    image={logo.gatsbyImageData}
                    objectFit={'contain'}
                    style={{ height: '100%', width: '80%' }}
                />
            </LogoContainer>
            <DescriptionList>
                <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                    {t('company')}
                </SwTypography>
                <SwTypography component={'dd'}>{description}</SwTypography>
                <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                    {t('size')}
                </SwTypography>
                <SwTypography component={'dd'}>{companySize}</SwTypography>
                <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                    {t('industry')}
                </SwTypography>
                <SwTypography component={'dd'}>{industry}</SwTypography>
                <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                    {t('headquarters')}
                </SwTypography>
                <SwTypography component={'dd'}>{headquarter}</SwTypography>
                <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                    {t('website')}
                </SwTypography>
                <SwTypography component={'dd'}>
                    <SwLink href={website} rel={'noreferrer'} target={'_blank'}>
                        {getDomaineName(website)}
                    </SwLink>
                </SwTypography>
            </DescriptionList>
        </SwPaper>
    );
};
