import { ArrowRight, CaretRight } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery, styled } from '@mui/material';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwContentCard } from '@lib/components/SwContentCard';
import { SwContentLayout } from '@lib/components/SwContentLayout';
import { SwFeatureCard, SwFeatureCardProps } from '@lib/components/SwFeatureCard';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { SwSharingLinksSection } from '@lib/components/SwSharingLinksSection';
import { SwTableOfContents } from '@lib/components/SwTableOfContents';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { AspectRatios } from '@lib/enums/apectRatios';
import WebsiteSectionsLayout from '@app/common/components/WebisteSectionsLayout';
import { CardGatInTouch } from '@app/story/CardGetInTouch';
import { CompanyMeta } from '@app/story/CompanyMeta';
import { StoryMeta } from '@app/story/storyMeta';
import FlashBanner from '../app/common/components/FlashBanner';
import Seo from '../app/common/components/Seo';
import SwLayout from '../components/v1/SwLayout';
import SwBreadcrumb from '../components/v1/website/SwBreadcrumb';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import { black, grey3, grey5, grey6 } from '../lib/components/bedrock/SwColors';
import SwWebsiteContainer from '../lib/components/layout/SwWebsiteContainer';

const Header = styled('div')`
    padding-block-start: 32px;
`;

const CoverImage = styled('div')`
    position: relative;
    width: 100%;
    aspect-ratio: ${AspectRatios.widescreen};
    max-height: 580px;
    min-height: 380px;
    background: ${grey5};
    border-radius: 8px;
    z-index: 0;
    border: 1px solid ${grey5};
    overflow: hidden;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        min-height: auto;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        aspect-ratio: ${AspectRatios.portrait};
    }
`;

const SidebarContainer = styled('div')`
    display: flex;
    gap: 16px;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-inline: 20px;
    }
`;

const StyledCompanyMeta = styled(CompanyMeta)`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    max-width: 330px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        position: relative;
        top: unset;
        left: unset;
        max-width: 100%;
    }
`;

const Story = ({ data, pageContext: { slug } }) => {
    const story = data.graphCmsCustomerStory;
    const availableLocalizations = data.customerStoryLocalizations.nodes.filter(({ locale }) =>
        data.customerStoryLocalizations.distinct.includes(locale)
    );
    const isTabletOrLess = useMediaQuery(useTheme().breakpoints.down('md'));
    const { t } = useTranslation();

    const title = story.title.split(story.company.name);

    return (
        <>
            <Seo
                description={story.seo.description}
                image={story.seo.image?.url}
                noIndex={story.seo.noIndex}
                pathname={slug}
                title={story.seo.title}
            />

            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.resources.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
                localizedSlugs={availableLocalizations.map(({ locale, slug }) => ({
                    locale,
                    slug: `/${slug}`
                }))}
            />
            <SwWebsiteContainer>
                <SwLayout background={grey6}>
                    <SwBreadcrumb
                        basePage={WEBSITE_MENU_ITEM.stories.text}
                        baseUrl={WEBSITE_MENU_ITEM.stories.link}
                        page={story.company.name}
                    />
                    <WebsiteSectionsLayout>
                        <div>
                            <SwContainer>
                                <SwGrid columns={1} gap={16}>
                                    <Header>
                                        <SwGrid columns={{ xs: ['100%'], md: ['60%', 'auto'] }} gap={32}>
                                            <SwGrid.Item column={1}>
                                                <SwTypography color={grey3} component={'h1'} variant={'h3'}>
                                                    <SwTypography color={black} component={'span'} variant={'h3'}>
                                                        {story.company.name}
                                                    </SwTypography>{' '}
                                                    {title[1]}
                                                </SwTypography>
                                            </SwGrid.Item>
                                            <SwGrid.Item column={{ xs: 1, md: 2 }}>
                                                <SwFlexBox flexDirection={'column'} gap={16}>
                                                    <CoverImage>
                                                        {!isTabletOrLess && (
                                                            <StyledCompanyMeta
                                                                companySize={story.company.size}
                                                                description={story.company.description}
                                                                headquarter={story.company.headquarter}
                                                                industry={story.company.industry}
                                                                logo={story.company.logo}
                                                                style={{ borderTop: 'none', borderLeft: 'none' }}
                                                                website={story.company.website}
                                                            />
                                                        )}
                                                        {story.company.images?.length ? (
                                                            <GatsbyImage
                                                                alt={story.company.images[0].alt}
                                                                image={story.company.images[0].gatsbyImageData}
                                                                objectFit={'cover'}
                                                                style={{ height: '100%', width: '100%' }}
                                                            />
                                                        ) : null}
                                                    </CoverImage>
                                                    {isTabletOrLess && (
                                                        <StyledCompanyMeta
                                                            companySize={story.company.size}
                                                            description={story.company.description}
                                                            headquarter={story.company.headquarter}
                                                            industry={story.company.industry}
                                                            logo={story.company.logo}
                                                            website={story.company.website}
                                                        />
                                                    )}
                                                </SwFlexBox>
                                            </SwGrid.Item>
                                        </SwGrid>
                                    </Header>
                                    {story.stats.children.length ? (
                                        <>
                                            <SwLine color={grey5} direction={'horizontal'} spacing={0} />
                                            <SwCmsRenderer content={story.stats} />
                                        </>
                                    ) : null}

                                    <SwLine color={grey5} direction={'horizontal'} spacing={0} spacingBottom={2} />
                                </SwGrid>
                            </SwContainer>
                            <SwContainer paddingInline={{ xs: '0px' }}>
                                <SwContentLayout
                                    leftSide={
                                        <SidebarContainer>
                                            <StoryMeta
                                                category={story.category}
                                                collapsible={isTabletOrLess}
                                                published={story.published}
                                                tags={story.tags}
                                            />
                                            <SwTableOfContents json={story.content.json} />
                                        </SidebarContainer>
                                    }
                                    rightSide={
                                        <SidebarContainer>
                                            <SwSharingLinksSection slug={slug} title={story.title} />
                                            <CardGatInTouch />
                                        </SidebarContainer>
                                    }
                                >
                                    <SwContentCard content={story.content} />
                                </SwContentLayout>
                            </SwContainer>
                            <SwContainer>
                                <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={16} width={'100%'}>
                                    <SwLine color={grey5} direction={'horizontal'} spacingBottom={0} spacingTop={4} />
                                    <SwFlexBox alignItems={'center'} gap={4}>
                                        <SwTypography bold={true} color={grey3} component={'h2'} variant={'body1'}>
                                            {t('customer_story_page.section_features_title')}
                                        </SwTypography>
                                        <CaretRight color={grey3} fontWeight={'bold'} />
                                    </SwFlexBox>
                                    <SwGrid columns={{ xs: 1, sm: 2, md: 3 }}>
                                        {story.keyFeatures.map(
                                            ({ id, ...rest }: SwFeatureCardProps & { id: string }) => (
                                                <SwFeatureCard key={id} {...rest} titleHeading={'h3'} />
                                            )
                                        )}
                                    </SwGrid>
                                    <SwInternalLink
                                        size={'large'}
                                        to={WEBSITE_MENU_ITEM.platform.link}
                                        variant={'secondary'}
                                    >
                                        {t('customer_story_page.section_features_action_label')} <ArrowRight />
                                    </SwInternalLink>
                                </SwFlexBox>
                            </SwContainer>
                        </div>
                        <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={32} width={'100%'}>
                            <SwContainer>
                                <SwFlexBox alignItems={'center'} gap={4}>
                                    <SwTypography bold={true} color={grey3} component={'h2'} variant={'body1'}>
                                        {t('customer_story_page.section_more_stories_title')}
                                    </SwTypography>
                                    <CaretRight color={grey3} fontWeight={'bold'} />
                                </SwFlexBox>
                            </SwContainer>
                            <SwContainer hasOverflowingContent={true}>
                                <SwLatestContent
                                    content={data.moreStories.nodes}
                                    renderItems={({ company, ...rest }) => (
                                        <SwRecordCard
                                            key={rest.id}
                                            record={{
                                                ...rest,
                                                company,
                                                coverImage: {
                                                    alt: company.images?.[0]?.alt,
                                                    gatsbyImageData: company.images?.[0]?.gatsbyImageData
                                                }
                                            }}
                                            titleHeading={'h3'}
                                            variant={'compact'}
                                            width={{ xs: 300, md: 'auto' }}
                                        />
                                    )}
                                />
                            </SwContainer>
                        </SwFlexBox>
                    </WebsiteSectionsLayout>
                    <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        graphCmsCustomerStory: { seo }
    }
}) => (
    <Seo
        description={seo.description}
        image={seo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const getStoryQuery = graphql`
    query getStoryQuery($id: String!, $remoteId: ID!, $locale: GraphCMS_Locale = en) {
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 3
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        moreStories: allGraphCmsCustomerStory(
            filter: { locale: { eq: $locale }, actualLocale: { eq: $locale }, id: { ne: $id } }
            sort: { order: DESC, fields: published }
            limit: 3
        ) {
            nodes {
                ...CustomerStoryCard_GraphCMS_CustomerStory
            }
        }
        customerStoryLocalizations: allGraphCmsCustomerStory(
            filter: { remoteId: { eq: $remoteId }, seo: { noIndex: { ne: true } } }
        ) {
            distinct(field: actualLocale)
            nodes {
                slug
                locale
            }
        }
        graphCmsCustomerStory(id: { eq: $id }) {
            id
            category {
                label
            }
            published
            title
            slug
            locale
            tags {
                id: remoteId
                label
                color
            }
            company {
                ...Extended_GraphCMS_Company
            }
            stats {
                ...Essentials_GraphCMS_Grid
                children {
                    ... on GraphCMS_GridItem {
                        ...Essentials_GraphCMS_GridItem
                        children {
                            ...Essentials_GraphCMS_Stat
                        }
                    }
                }
            }
            content {
                json
                references {
                    ...Essentials_GraphCMS_Asset
                    ...Essentials_GraphCMS_Quote
                    ...Essentials_GraphCMS_CustomerQuote
                    ...Essentials_GraphCMS_CallOut
                }
            }
            keyFeatures {
                ...Essentials_GraphCMS_FeatureCard
            }
            seo {
                ...Essentials_GraphCMS_Seo
            }
        }
    }
`;

export default Story;
