import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { grey3 } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';

const Container = styled(SwPaper)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    justify-content: start;
`;

export const CardGatInTouch = (props) => {
    const { t } = useTranslation();

    return (
        <Container {...props}>
            <SwTypography color={grey3} variant={'body2'}>
                {t('ready_to_get_your_carbon_on_track')}
            </SwTypography>
            <SwInternalLink to={WEBSITE_MENU_ITEM.getInTouch.link}>{t('get_in_touch')}</SwInternalLink>
        </Container>
    );
};
